export default() => ({
    init(){
        const tables = this.$root.querySelectorAll("table")
        if (tables.length == 0)
            return

        tables.forEach(table => {
            const div = document.createElement("div")
            div.className = "table-wrap"
            table.after(div)
            div.append(table)
        });
    }
})