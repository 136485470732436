import { decode } from 'html-entities';

export default (objectsInfo) => ({
    d3: false,
    originalWidth: 1864,
    originalHeight: 816,
    bgScale: 1,
    svg: false,
    image: false,
    zoom: false,
    objects: [],
    prevIndex: 0,
    currentIndex: -1,
    nextIndex: 0,
    prevName: "",
    nextName: "",
    currentObject: {
        name: "",
        description: "",
        elementID: "",
    },
    init() {
        this.objects = objectsInfo;

        this.prevIndex = this.objects.length - 1;
        this.nextIndex = 1;

        this.$watch("currentIndex", (value) => this.onCurrentIndexChange(value));
        this.initZoom();
    },
    onCurrentIndexChange(currentIndex) {
        if (currentIndex < 0) {
            this.currentObject.name = "";
            this.currentObject.description = "";
            this.currentObject.elementID = "";
            return;
        }

        this.prevIndex =
            currentIndex - 1 < 0 ? this.objects.length - 1 : currentIndex - 1;
        this.nextIndex =
            currentIndex + 1 == this.objects.length ? 0 : currentIndex + 1;

        const currentObject = this.objects[currentIndex];
        this.currentObject.name = currentObject.name;
        this.currentObject.description = decode(currentObject.description);
        this.currentObject.elementID = currentObject.elementID;

        const prevObject = this.objects[this.prevIndex];
        this.prevName = prevObject.name;

        const nextObject = this.objects[this.nextIndex];
        this.nextName = nextObject.name;
    },
    onObjectClick(event) {
        const elementID = this.$el.id;
        const currentIndex = this.objects.findIndex(
            (object) => object.elementID == elementID
        );
        if (currentIndex < 0) return;

        this.currentIndex = currentIndex;
    },
    clickOutside(event) {
        if (!event.target.matches(".description-box *, .zoom-in, .zoom-out"))
            this.reset();
    },
    reset() {
        this.currentIndex = -1;
    },
    zoomIn() {
        const transform = this.d3.zoomTransform(this.svg.node());
        this.zoom.scaleTo(this.svg.transition(), transform.k + 1);
    },
    zoomOut() {
        const transform = this.d3.zoomTransform(this.svg.node());
        this.zoom.scaleTo(this.svg.transition(), transform.k - 1);
    },
    toNext() {
        this.currentIndex =
            this.currentIndex + 1 == this.objects.length ? 0 : this.currentIndex + 1;
    },
    toPrev() {
        this.currentIndex =
            this.currentIndex - 1 < 0
                ? this.objects.length - 1
                : this.currentIndex - 1;
    },
    async initZoom() {
        const d3 = await import("d3");
        this.d3 = d3;

        this.svg = d3.select(this.$refs.zoomRoot);
        this.image = d3.select(this.$refs.mapWrap);

        const zoom = d3.zoom().on("zoom", (event) => {
            requestAnimationFrame(() => {
                const { transform } = event;
                this.$refs.mapWrap.style.transform = `translate(${transform.x}px, ${transform.y}px) scale(${transform.k})`;
            });
        });
        this.zoom = zoom;

        this.svg.call(this.zoom);
        this.svg.on("dblclick.zoom", null);

        this.calcSizes();
    },
    setBgScale() {
        const scale = Math.min(
            this.$root.clientHeight / this.originalHeight,
            this.$root.clientWidth / this.originalWidth
        );
        this.bgScale = scale > 1 ? 1 : scale;
    },
    updateExtents() {
        const { width: svgWidth, height: svgHeight } = this.image
            .node()
            .getBoundingClientRect();

        this.zoom
            .scaleExtent([1, 3])
            .extent([
                [0, 0],
                [svgWidth, svgHeight],
            ])
            .translateExtent([
                [0, 0],
                [svgWidth, svgHeight],
            ]);

        this.zoom.scaleTo(this.svg, 1);
    },
    calcSizes() {
        this.setBgScale();
        this.updateExtents();
    },
    isMobile() {
        return matchMedia("(max-width: 1279px)").matches;
    },
});
