import "./scss/main.scss";
import "swiper/css";

import "./js/alpine";

import "./js/modal";

window.addEventListener("load", () => {
    import("htmx.org");
});
