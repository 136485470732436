export default () => ({
  goTo() {
    if (!this.$root.hash) return;

    const target = document.querySelector(this.$root.hash);
    if (!target) return;

    window.scrollBy({
      behavior: "smooth",
      top: target.offsetTop - window.scrollY,
    });
  },
});
