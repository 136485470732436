import Alpine from "alpinejs";

import MainMap from "./MainMap";
Alpine.data("MainMap", MainMap);

import CustomScrollbar from "./CustomScrollbar";
Alpine.data("CustomScrollbar", CustomScrollbar);

import ContactsMap from "./ContactsMap";
Alpine.data("ContactsMap", ContactsMap);

import Accordion from "./Accordion";
Alpine.data("Accordion", Accordion);

import HowToGetMap from "./HowToGetMap";
Alpine.data("HowToGetMap", HowToGetMap);

import PhoneInputMask from "./PhoneInputMask";
Alpine.data("PhoneInputMask", PhoneInputMask);

import NumberInputMask from "./NumberInputMask";
Alpine.data("NumberInputMask", NumberInputMask);

import HotelPreviewSlider from "./HotelPreviewSlider";
Alpine.data("HotelPreviewSlider", HotelPreviewSlider);

import RangeInput from "./RangeInput";
Alpine.data("RangeInput", RangeInput);

import FancyboxGallery from "./FancyboxGallery";
Alpine.data("FancyboxGallery", FancyboxGallery);

import Anchor from "./Anchor";
Alpine.data("Anchor", Anchor);

import OrderForm from "./OrderForm";
Alpine.data("OrderForm", OrderForm);

import DateInput from "./DateInput"
Alpine.data("DateInput", DateInput)

import CustomBookingForm from "./CustomBookingForm"
Alpine.data("CustomBookingForm", CustomBookingForm)

import ContentTableWrap from "./ContentTableWrap"
Alpine.data("ContentTableWrap", ContentTableWrap)

Alpine.store("mainMenu", {
    open: false,
});

Alpine.store("sessid", window.sessid);

Alpine.start();