export default () => ({
  async init() {
    const { default: Swiper } = await import("../libs/Swiper");

    new Swiper(this.$root, {
      speed: 500,
      pagination: {
        el: this.$refs.pagination,
        clickable: true,
      },
    });
  },
});
