export default (services, guestsInfo, selectedServices = []) => ({
    services: [],
    selectedServices: [],
    guestsInfo: [],
    init() {
        this.services = JSON.parse(services)
        this.guestsInfo = JSON.parse(guestsInfo)

        if (selectedServices.length > 0) {
            this.selectedServices = this.services.map((value) => selectedServices.includes(+value.ID) && value).filter(Boolean)
        }

    },
    deleteService(id) {
        const index = this.selectedServices.findIndex((value) => value.ID == id)
        if (index == -1)
            return

        this.selectedServices.splice(index, 1)
    },
    selectService(id) {
        if (this.selectedServices.findIndex((value) => value.ID == id) !== -1)
            return

        this.selectedServices.push(this.services.find((value) => value.ID == id))
    },
    addGuest() {
        this.guestsInfo.push({
            name: "",
            birth_date: "",
            passport: "",
            passport_date: "",
            passport_org: ""
        })
    }
})