import { formatNumber } from "../utils/formatNumber";

export default ({ unit, start, step = 1, min = 0, max = 100 }) => ({
  value: {
    min: start[0],
    max: start[1],
  },
  async init() {
    import("nouislider/dist/nouislider.min.css");
    const { default: noUiSlider } = await import("nouislider");

    const slider = noUiSlider.create(this.$refs.range, {
      start,
      step,
      connect: true,
      tooltips: false,
      pips: {
        mode: "range",
        stepped: true,
        density: 25,
        format: this.formatter,
      },
      range: {
        min,
        max,
      },
    });

    slider.on("set", (value, handleNumber, unencoded) => {
      const current = slider.get(true);
      this.value = {
        min: current[0],
        max: current[1],
      };

      this.$root.dispatchEvent(
        new CustomEvent("rangeValuesChange", {
          bubbles: true,
        })
      );
    });
  },
  formatter: {
    to: (value) => {
      return `${formatNumber(value)} ${unit}`;
    },
  },
});
