import { showModal } from "../modal";

export default (status = "init") => ({
  init() {
    if (status === "not_valid") this.scrollToContacts();
    else if (status === "success") showModal("modal-order-success");
    else if (status === "error") showModal("modal-order-error");
  },
  scrollToContacts() {
    window.scrollBy({
      behavior: "smooth",
      top: this.$refs.contacts.offsetTop - window.scrollY - 60,
    });
  },
});
